<template>
  <div>
    <!-- Modal form -->
    <b-modal
      id="modal-form"
      title="Tambah Jadwal Praktek"
      ok-title="Simpan"
      cancel-title="Batal"
      @ok="modalOnSubmit"
      @hidden="modalOnHide"
      @show="modalOnShow">
      <b-form @submit.stop.prevent="formOnSubmit">
        <!-- Doctor Select -->
        <template v-if="!hasDoctor">
          <b-form-group
            id="input-group-doctor"
            label="Pilih Dokter:"
            label-for="input-doctor">
            <treeselect
              v-model="form.doctor_id"
              :multiple="false"
              :options="doctors"
              placeholder="Pilih Dokter" />
            <small class="text-danger">{{ error.doctor_id }}</small>
          </b-form-group>
        </template>

        <!-- Days Select -->
        <b-form-group
          id="input-group-schedule"
          label="Jadwal Praktek:"
          label-for="input-schedule">
          <b-form-select
            v-model="form.day_name"
            :options="days"></b-form-select>
          <small class="text-danger">{{ error.day_name }}</small>
        </b-form-group>

        <!-- StartTime Picker -->
        <b-form-group
          id="input-group-start-time"
          label="Jam Mulai:"
          label-for="input-start-time">
          <!-- <b-form-timepicker
            v-model="form.start_time"
            locale="id"
            placeholder="Jam Mulai"
            now-button
            label-now-button="Waktu Sekarang"
            reset-button
            minutes-step="5"
          ></b-form-timepicker> -->

          <div class="d-flex">
            <div class="col-md-2 p-1">
              <b-form-input
                id="input-time"
                placeholder="Jam"
                v-model="start_time.time"
                class="text-center"></b-form-input>
            </div>
            <div
              class="
                col-md-1
                p-1
                d-flex
                justify-content-center
                align-items-center
              ">
              <span class="font-weight-bold">:</span>
            </div>
            <div class="col-md-2 p-1">
              <b-form-input
                id="input-time-minute"
                placeholder="Menit"
                v-model="start_time.minute"
                class="text-center"></b-form-input>
            </div>
          </div>
          <small class="text-danger">{{ error.start_time }}</small>
        </b-form-group>

        <!-- EndTime Picker -->
        <b-form-group
          id="input-group-end-time"
          label="Jam Selesai:"
          label-for="input-end-time">
          <!-- <b-form-timepicker
            v-model="form.end_time"
            placeholder="Jam Selesai"
            now-button
            label-now-button="Waktu Sekarang"
            reset-button
            locale="id"
            minutes-step="5"
          ></b-form-timepicker> -->

          <div class="d-flex">
            <div class="col-md-2 p-1">
              <b-form-input
                id="input-end-time"
                placeholder="Jam"
                v-model="end_time.time"
                class="text-center"></b-form-input>
            </div>
            <div
              class="
                col-md-1
                p-1
                d-flex
                justify-content-center
                align-items-center
              ">
              <span class="font-weight-bold">:</span>
            </div>
            <div class="col-md-2 p-1">
              <b-form-input
                id="input-end-time-minute"
                placeholder="Menit"
                v-model="end_time.minute"
                class="text-center"></b-form-input>
            </div>
          </div>

          <small class="text-danger">{{ error.end_time }}</small>
        </b-form-group>

        <!-- Input Duration -->
        <b-form-group
          id="input-group-duration"
          label="Durasi:"
          label-for="input-duration">
          <b-form-select
            v-model="form.duration"
            :options="durations"></b-form-select>
          <small class="text-danger">{{ error.duration }}</small>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import DoctorModule from "@/core/modules/DoctorModule"
import Treeselect from "@riophae/vue-treeselect"
import validation from "@/core/modules/ValidationModule.js"
import module from "@/core/modules/CrudModule.js"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

export default {
  components: {
    Treeselect,
  },

  props: {
    form: Object,
    route: String,
    editTarget: String,
    hasDoctor: Boolean,
  },

  data() {
    return {
      error: {
        day_name: "",
        start_time: "",
        end_time: "",
        doctor_id: "",
        duration: "",
      },
      doctors: [{ label: "Pilih Dokter", id: "", isDisabled: true }],
      durations: [
        { value: "", text: "Pilih Durasi (Menit)", disabled: true },
        { value: 15, text: "15 Menit" },
        { value: 20, text: "20 Menit" },
        { value: 30, text: "30 Menit" },
        { value: 45, text: "45 Menit" },
        { value: 60, text: "60 Menit" },
      ],
      days: [
        { value: "", text: "Pilih Hari", disabled: true },
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
        "Minggu",
      ],
      start_time: {
        time: "",
        minute: "",
      },
      end_time: {
        time: "",
        minute: "",
      },
    }
  },

  methods: {
    resetDays() {
      this.days = [
        { value: "", text: "Pilih Hari", disabled: true },
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
        "Minggu",
      ]
    },

    async getDoctorsOption() {
      const data = await DoctorModule.getTreeSelect()
      this.doctors.push(...data)
    },

    async formOnSubmit() {
      // check if time empty
      if (this.start_time.time == "") {
        this.start_time.time = "00";
      }

      if (this.start_time.minute == "") {
        this.start_time.minute = "00";
      }

      if (this.end_time.time == "") {
        this.end_time.time = "00";
      }

      if (this.end_time.minute == "") {
        this.end_time.minute = "00";
      }

      this.form.start_time =
        this.start_time.time + ":" + this.start_time.minute;
      this.form.end_time = this.end_time.time + ":" + this.end_time.minute;

      let route;
      this.editTarget == ""
        ? (route = this.route)
        : (route = `${this.route}/${this.editTarget}`);
      let response = await module.submit(this.form, route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        this.$root.$emit("UpdateAvailableDoctor");
        Swal.fire(response.success.title, response.success.message, "success");

        this.start_time.time = "";
        this.start_time.minute = "";
        this.end_time.time = "";
        this.end_time.minute = "";

        if (typeof this.$route.query.redirect != "undefined") {
          this.$router.push({ name: this.$route.query.redirect });
          return;
        }
        // Hide Modal
        this.$bvModal.hide("modal-form");
        // Refresh Table
        this.$emit("formOnSubmit");
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault();
      this.formOnSubmit();
    },

    modalOnShow() {
      this.error = validation.clearValidationError(this.error);
      if (this.hasDoctor) {
        // this.selectDoctorOnChange({ id: this.$route.params.id })
        this.form.doctor_id = this.$route.params.id;
      } else {
        // Reset Days Option
        this.resetDays();
      }
    },

    modalOnHide() {
      this.error = validation.clearValidationError(this.error);
      this.form = validation.clearForm(this.form);

      this.start_time.time = "";
      this.start_time.minute = "";
      this.end_time.time = "";
      this.end_time.minute = "";

      // Reset Form
      delete this.form["_method"];
      if (this.hasDoctor) {
        // this.selectDoctorOnChange({ id: this.$route.params.id })
        this.form.doctor_id = this.$route.params.id;
      } else {
        // Reset Days Option
        this.resetDays();
      }
    },
  },

  watch: {
    "form.doctor_id": function (newVal) {
      if (typeof newVal === "undefined") this.form.doctor_id = "";
    },
  },

  mounted() {
    if (!this.hasDoctor)
      this.getDoctorsOption()
  }

}
</script>