<template>
  <v-row>
    <v-col>
      <!-- Filter By Doctor -->
      <div class="row justify-content-end align-items-center mb-2">
        <div class="col-md-3 co-12">
          <treeselect
            id="input-doctor"
            v-model="filter.doctor_id"
            :multiple="false"
            :options="doctors"
            placeholder="Filter Berdasar Dokter"
            @select="filterDoctorOnSelect"
          />
        </div>
      </div>
      <!-- Calendar -->
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          :now="today"
          :value="today"
          :events="events"
          :event-color="getEventColor"
          color="primary"
          type="week"
          locale="id"
          @click:event="showEvent"
          @change="getEvents"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-toolbar-title
                v-html="`Praktek ${selectedEvent.name}`"
              ></v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text>
              <span
                v-html="
                  `<p style='font-size: 13px'>${selectedEvent.details}</p>`
                "
              ></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import DoctorModule from "@/core/modules/DoctorModule";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },

  data: () => ({
    filter: {
      doctor_id: "",
    },
    doctors: [{ label: "Pilih Dokter", id: "", isDisabled: true }],
    today: new Date().toISOString().substr(0, 10),
    events: [],
    colors: ["bg-filed", "bg-conf-wait", "bg-conf", "bg-handled", "bg-danger"],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  methods: {
    async getDoctors() {
      const data = await DoctorModule.getTreeSelect();
      this.doctors.push(...data);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        let dayName = new Date(event.start).toLocaleDateString("id-ID", {
          weekday: "long",
        });
        let start = new Date(event.start).toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        });
        let end = new Date(event.end).toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        });
        this.selectedEvent.details = `Praktek ${event.name} Pada ${dayName} ${start} s/d ${end}`;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 30);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 30);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    getEventColor(event) {
      return event.color;
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    async getAppointment() {
      return await module.list(
        `schedules/get-calendar?doctor_id=${this.filter.doctor_id}`
      );
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id;
      this.getEvents();
    },

    async getEvents() {
      let arr = [],
        date = this.getMonday(new Date());
      for (let i = 1; i <= 8; i++) {
        i == 1
          ? arr.push(new Date(date.setDate(date.getDate())))
          : arr.push(new Date(date.setDate(date.getDate() + 1)));
      }
      const events = [];
      const schedules = await this.getAppointment(this.filter.doctor_id);
      let start, end;
      for (let i = 0; i < schedules.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (
            schedules[i].day_name ==
            new Date(arr[j]).toLocaleString("id-ID", { weekday: "long" })
          ) {
            start = new Date(arr[j]);
            start.setHours(schedules[i].start_hour);
            start.setMinutes(schedules[i].start_minute);
            end = new Date(arr[j]);
            end.setHours(schedules[i].end_hour);
            end.setMinutes(schedules[i].end_minute);
            let day_number = start.getDay();
            if (day_number == 0) {

              // Kurangi 6 hari
              start.setDate(start.getDate() - 7);
              end.setDate(end.getDate() - 7);

            }
            events.push({
              name: schedules[i].name,
              weekdays: 1,
              start: start,
              end: end,
              color: this.colors[this.random(0, this.colors.length - 1)],
              timed: true,
            });
          }
        }
      }
      this.events = events;
      console.log(
        "================================================================================ this.events",
        this.events
      );
    },

    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterDoctorOnSelect();
      }
    },
  },

  mounted() {
    this.$refs.calendar.scrollToTime("08:00");
    this.getDoctors();
  },
};
</script>

<style>
.bg-filed {
  background-color: #007bff !important;
}

.bg-conf-wait {
  background-color: #ffc107 !important;
  color: black !important;
}

.bg-conf {
  background-color: #28a745 !important;
}

.bg-handled {
  background-color: #17a2b8 !important;
}
</style>