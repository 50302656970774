<template>
  <div>

    <!-- Filter By Doctor -->
    <div
      class="row justify-content-end align-items-center"
      v-if="!hasDoctor">
      <div class="col-md-3 col-12">
        <treeselect
          id="input-doctor"
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          placeholder="Filter Berdasar Dokter"
          @select="filterDoctorOnSelect" />
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      class="mt-3"
      style="white-space: nowrap: max-width: 100%"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '10%' : '' }" />
      </template>
      <template #cell(actions)="data">
        <!-- <b-button
          size="sm"
          class="mr-1 btn-success"
          @click="btnEditOnClick(data.item.id)"
          v-b-modal.modal-form
        > Edit </b-button> -->
        <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          v-if="manipulateBtn == true"
          @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

  </div>
</template>

<script>

import DoctorModule from "@/core/modules/DoctorModule"
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  components: {
    Treeselect
  },

  props: {
    fields: Array,
    items: Array,
    hasDoctor: Boolean,
    perPage: Number,
    currentPage: Number,
    totalRows: Number
  },

  data() {
    return {
      filter: {
        doctor_id: ''
      },
      doctors: [{ label: "Filter Berdasar Dokter", id: "", isDisabled: true }],
      manipulateBtn: false,
    }
  },

  methods: {

    async getDoctors() {
      const data = await DoctorModule.getTreeSelect()
      this.doctors.push(...data)
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.$emit('pageOnClick', page)
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id
      this.$emit('filterDoctorOnSelect', this.filter.doctor_id)
    },

    btnEditOnClick(id) {
      this.$emit('btnEditOnClick', id)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "4002") {
          this.manipulateBtn = true
        }
      }
    }

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterDoctorOnSelect()
      }
    }
  },

  mounted() {
    this.setActiveMenu()
    if (!this.hasDoctor) this.getDoctors()
  }

}
</script>

<style></style>